import React from "react";
import './portfolioComponent.css'
import WebProject from "./projectComponent";
import $ from 'jquery';
import ProjectInfo from "./projectInfo";

class Portfolio extends React.Component{

  constructor(props){
    super(props);

  }//end constructor

  componentDidMount(){
    if($(window).width() >= 992)
      $("#portfolio-section").width($(window).width() - $("#offcanvas").innerWidth()).css("margin-left" , `${$("#offcanvas").innerWidth()}px`); 
    
      $(window).resize(()=>{
        if($(window).width() >= 992)
        $("#portfolio-section").width($(window).width() - $("#offcanvas").innerWidth()).css("margin-left" , `${$("#offcanvas").innerWidth()}px`);
        else
          $("#portfolio-section").width($(window).width()).css("margin-left" , '0');
      });

  }//end componentDidMount

  render(){
    const webProjectList = [];
    for(let key in this.props.projects)
      webProjectList.push(<WebProject key={`project-${key}`} projectName={key} image={this.props.projects[key].images[0]} url={this.props.projects[key].url} viewDetailClick={this.props.viewDetailClick}/>)
    return(
      <section id="portfolio-section" className="py-3">
        <div className="container p-3">
          <header>
            <h1 className="main-heading">Portfolio</h1>
            <p>
              Following are some of the projects that I have developed during the Altcademy Bootcamp.
              They are simple projects that put in practice theories, concepts, and web technologies learned 
              in the program. Some of those technologies include HTML5, CSS3, JavaScript, jQuery, React Framework,
              Bootstrap Framework, Fetch API to make asynchronous HTTP Request, and so on.
            </p>
          </header>
          <div className="row row-cols-3 m-3 gy-4">
            {webProjectList}
          </div>
        </div>
      </section>
    );
  }//end render
}//end Portfolio

export default Portfolio;