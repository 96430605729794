import React from 'react';
import './projectComponent.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';

class WebProject extends React.Component{

  constructor(props){
    super(props);
  }//end constructor

  componentDidMount(){

    $(`#${this.props.projectName}`).parent().mouseenter((event) => {
      const rowElement = $(event.target).parent().find(".row");
      if(rowElement.hasClass("invisible"))
        rowElement.slideUp(0,()=>{
          rowElement.removeClass("invisible");
        }).slideDown(300);
      else
        rowElement.slideDown(300);           
    
    });

    $(`#${this.props.projectName}`).parent().mouseleave((event)=>{
      $(event.target).parent().find(".row").slideUp(300);
    });

  }//end componentDidMount

  componentWillUnmount(){

    $(`#${this.props.projectName}`).parent().unbind();

  }//end componentWillUnmount
  render(){
    return (
      <div className="col-12 col-md-6 col-lg-4">
        <div className="content ratio ratio-4x3">
          <img id={`${this.props.projectName}`} className="img-fluid" src={this.props.image} />
          <div className='options-bar border'>
            <div className='row m-0 invisible'>
              <div className='col text-center' title="View Details" onClick={this.props.viewDetailClick}>
                <Link to="/portfolio-details"><span className='fas fa-plus py-3 w-100 text-white'></span></Link>
              </div>
              <div className='col text-center' title='Explore App'>
                <a href={`${this.props.url}`} target="_blank">
                  <span className='fas fa-link py-3 w-100 text-white'></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }//end render

}//end WebProject

export default WebProject;