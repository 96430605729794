class ProjectInfo{
  constructor(name , date , url , description, externalLibraries, ...images){
    this.name = name;
    this.date = date;
    this.url = url;
    this.description = description;
    this.images = images;
    this.externalLibraries = externalLibraries;
  }
}//end ProjectInfo

export default ProjectInfo;