import React from "react";
import './contactComponent.css';
import $ from 'jquery';

class Contact extends React.Component{

  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }//end constructor

  componentDidMount(){

    if($(window).width() >= 992)
      $("#contact-section").width($(window).width() - $("#offcanvas").innerWidth()).css("margin-left" , `${$("#offcanvas").innerWidth()}px`);

    $(window).resize(()=>{
        if($(window).width() >= 992)
          $("#contact-section").width($(window).width() - $("#offcanvas").innerWidth()).css("margin-left" , `${$("#offcanvas").innerWidth()}px`)
        else
          $("#contact-section").width($(window).width()).css("margin-left" , '0');});

  }//end componentDidMount

  componentWillUnmount(){
    $(window).unbind();
  }//end componentWillUnmount

  displayAlertMessage(message, classIcon , classColor){
    //Show and then hide the email alert message
    $("#email-alert").html(`<span class="${classIcon}" width="24" height="24"></span> <div>${message}</div>`).addClass(classColor).fadeTo(2000 , 1).delay(2000).fadeOut(2000 , ()=>{

      $("#email-form").removeClass("was-validated"); //Remove bootstrap styles for viladating form controls

      $("#email-alert").css({
        "z-index": "0",
        "opacity": "0"
      }); //set the email alert message on the bottom of the stack

      $("#send-message-btn").removeAttr("disabled");   //activate the send Message Button

      $("#email-form").get(0).reset(); //Reset form controls
    });
  }//end displayAlertMessage

  handleClick(event){
    $(event.target.form).addClass("was-validated");
    //disable the send message button
    $("#send-message-btn").attr("disabled" , true);

    $("#email-alert").css("z-index" , "997"); //set the email alert message on top of the stack
    if(event.target.form.checkValidity())
    {
        let userName = $("#user-name").val();          //get user name
        let userEmail = $("#user-email").val();        //get user email
        let userSubject = $("#user-subject").val();    //get user subject
        let userMessage = $("#user-message").val();    //get user message

        //data to send on the request
        let dataToSend = {  
          subject: userSubject,
          message: userMessage,
          from: userEmail,
          fullName: userName
        }

        //url of the web api
        let url = "https://annia-app.herokuapp.com/emails/create";

        //making the request
        if(window.fetch)
        {
          fetch(url , {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend)
          }).then((response) => {
            if(response.ok)
              return response.json();
            else
              throw new Error(`Response status: ${response.status}\nResponse Message: ${response.statusText}`);
          }).then((jsonData)=>{
            if(jsonData.status === "ok")
            {
              //animate the email alert message
              this.displayAlertMessage("Email has been sent!", "fas fa-check-circle" , "alert-primary");
            }
          }).catch((error)=>{
            //animate the email alert message in case of an error
            this.displayAlertMessage("Network Issues.<br/>Try it later!" , "fas fa-exclamation-triangle" , "alert-danger");
          });
        }
    }
    else
    {
      //after two second removes bootstrap validation styles and 
      //activate the send Message button again
      setTimeout(()=>{
        $(event.target.form).removeClass("was-validated");
        $("#send-message-btn").removeAttr("disabled");
      }, 2000);
    }
    
  }//end handleClick

  render(){
    return(
      <section id="contact-section">
        <div className="container p-3">
          <h1 className="main-heading">Contact</h1>
          <p>
            Email or message me with any questions. I would be happy to answer you as soon as possible.
          </p>
          <div className="row my-3 py-3">
            {/* first column */}
            <div className="col-12 col-lg-5">
              <div className="content p-3">
                {/* first subrow */}
                <div className="row">
                  {/* first column */}
                  <div className="col-auto">
                    <div className="content">
                      <span className="fas fa-street-view"></span>
                    </div>
                  </div>
                  {/* end first column */}

                  {/* second column */}
                  <div className="col">
                    <div className="content">
                      <h4>Location</h4>
                      <p id="my-address">North Kendall Drive, Miami, FL 33176</p>
                    </div>
                  </div>
                  {/* end second column */}
                </div>
                {/* end first subrow */}

                {/* second subrow */}
                <div className="row">
                  {/* first column */}
                  <div className="col-auto">
                    <div className="content">
                      <span className="fas fa-envelope-open"></span>
                    </div>
                  </div>
                  {/* end first column */}

                  {/* second column */}
                  <div className="col">
                    <div className="content">
                      <h4>Email</h4>
                      <p id="my-email">portfolioercarranza@gmail.com</p>
                    </div>
                  </div>
                  {/* end second column */}
                </div>
                {/* end second subrow */}

                {/* third subrow */}
                <div className="row">
                  {/* first column */}
                  <div className="col-auto">
                    <div className="content">
                      <span className="fas fa-mobile-alt"></span>
                    </div>
                  </div>
                  {/* end first column */}

                  {/* second column */}
                  <div className="col">
                    <div className="content">
                      <h4>Call or Message:</h4>
                      <p id="my-phone">+1 (813) 447 6579</p>
                    </div>
                  </div>
                  {/* end second column */}
                </div>
                {/* end third subrow */}
                <div className="ratio ratio-1x1">
                <iframe width="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.545114985362!2d-80.38407348542133!3d25.686369683671018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9c0db4f287bd7%3A0xb2c195c341953c4e!2sN%20Kendall%20Dr%2C%20Florida!5e0!3m2!1sen!2sus!4v1663177225456!5m2!1sen!2sus" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            </div>
            {/* end first column */}

            {/* second column */}
            <div className="col-12 col-lg-6 offset-lg-1">
              <div className="content p-3 position-relative">
                <div id="email-alert" className="alert w-50 d-flex align-items-center"></div>
                <form id="email-form" noValidate>
                  {/* first row */}
                  <div className="row py-3">
                    {/* first column */}
                    <div className="col-lg-6">
                      <div className="content">
                        <label htmlFor="user-name" className="form-label">Your Name</label>
                        <input type="text" required id="user-name" name="user-name" className="w-100 form-control"/>
                        <div className="invalid-feedback">Please provide your name</div>
                      </div>
                    </div>
                    {/* end first column */}

                    {/* second column */}
                    <div className="col-lg-6">
                    <div className="content">
                      <label htmlFor="user-email" className="form-label">Your Email</label>
                        <input type="email" required id="user-email" name="user-email" className="w-100 form-control"/>
                        <div className="invalid-feedback">Please provide an email</div>
                      </div>
                    </div>
                    {/* end second column */}
                  </div>
                  {/* end first row */}

                  {/* second row */}
                  <div className="row py-3">
                    <div className="col">
                      <div className="content">
                        <label htmlFor="user-subject" className="form-label">Subject</label>
                        <input className="w-100 form-control" type="text" id="user-subject" name="user-subject"/>
                      </div>
                    </div>
                  </div>
                  {/* end second row */}

                  {/* third row */}
                  <div className="row py-3">
                    <div className="col">
                      <div className="content">
                        <label htmlFor="user-message" className="form-label">Message</label>
                        <div className="ratio ratio-4x3">
                          <textarea className="w-100 form-control" required id="user-message" name="user-message"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end third row */}

                  {/* fourts row */}
                  <div className="row justify-content-center">
                    <div className="col-auto">
                      <input type="button" value="Send Message" className="btn text-white" id="send-message-btn" onClick={this.handleClick}/>
                    </div>
                  </div>
                  {/* end fourth row */}
                </form>
              </div>
            </div>
            {/* end second column */}
          </div>        
        </div>
      </section>
    )
  }//end render

}//end Contact

export default Contact;