import './homeComponent.css';
import React from 'react';
import $ from 'jquery';

class Home extends React.Component {

  constructor(props){
    super(props);
  }//end constructor

  componentDidMount(){
    if($(window).width() >= 992)
      $("#home-section").width($(window).width() - $("#offcanvas").innerWidth()).css("margin-left" , `${$("#offcanvas").innerWidth()}px`);

    $(window).resize(()=>{
      if($(window).width() >= 992)
        $("#home-section").width($(window).width() - $("#offcanvas").innerWidth()).css("margin-left" , `${$("#offcanvas").innerWidth()}px`);
      else
        $("#home-section").width($(window).width()).css('margin-left' , '0');
    });
      
  }//end componentDidMount

  componentWillUnmount(){
    $(window).unbind();
  }//end componentWillUnmount

  render(){
    return (
      <section id="home-section">
      <div className='container'>
        <div className='content'>
          <h1 id="developer-name" className='text-white text-center'>Eduardo Carranza</h1>
          <p id="developer-description" className='text-white text-center'>I'am <span className='py-2'>Front-end web developer</span></p>
        </div>
      </div>
    </section>
    );
  }//end render
}


export default Home;