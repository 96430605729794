/**************************************************************************************
 * sideBar.js
 * Eduardo Carranza
 * 
 * This file include the sideBar component that support
 * the application
 **************************************************************************************/
import React from "react";
import './sideBar.css'
import $ from 'jquery';
import profilePicture from './personal-pictures/Year Background Blue Original.jpg';

class SideBar extends React.Component{

  constructor(props){
    super(props);
  }//end constructor

  componentDidMount(){

    // show the offcanvas component for device's width grater than 992px
    if($(window).width() >= 992)
      $("#offcanvas").addClass("show");

    //show or hide the offcanvas component based on the browser's window resolution
    $(window).resize(()=>{
      if($(window).width() >= 992)
        $("#offcanvas").addClass("show");
      else
      $("#offcanvas").removeClass("show");
    });

    $("#main-nav a").click((event)=>{
      event.stopPropagation();
      if($(window).width() < 992)
      {
        $("#offcanvas").toggleClass("show");
        $("#offcanvas-toggle-btn span").toggleClass("fa-bars fa-times");
      }
        
    });

  }//end componentDidMount

  componentWillUnmount(){

    $(window).unbind();

  }//end componentWillUnmount

  render(){
    return (
      <div className="offcanvas offcanvas-start w-auto px-2" id="offcanvas" data-bs-backdrop="false">
        
        {/* header */}
        <div className="offcanvas-header">
          <div id="profile" className="text-center">
            <img width="104px" height="104px" src={profilePicture}/>
            <h3 className="text-white">Eduardo Carranza</h3>
            <nav className="nav" id="profile-nav">
              <a href="#"><span className="fa fa-facebook pt-2"></span></a>
              <a href="#"><span className="fa fa-twitter pt-2"></span></a>
              <a href="#"><span className="fa fa-instagram pt-2"></span></a>
            </nav>
          </div>
        </div>
        {/* end header */}
  
        {/* body */}
        <div className="offcanvas-body">
          <nav className="nav flex-column" id="main-nav">
            <a className="nav-link text-start" href="#home-section"><span className="fas fa-home"></span><span>Home</span></a>
            <a className="nav-link text-start" href="#about-section"><span className="fas fa-user"></span><span>About</span></a>
            <a className="nav-link text-start" href="#resume-section"><span className="fas fa-file"></span><span>Resume</span></a>
            <a className="nav-link text-start" href="#portfolio-section"><span className="fas fa-folder-open"></span><span>PortFolio</span></a>
            <a className="nav-link text-start" href="#contact-section"><span className="fas fa-envelope"></span><span>Contact</span></a>
          </nav>
        </div>
        {/* end body */}
  
        <footer className="p-3">
          <div className="text-white text-center">&copy; Copyright</div>
          <div className="text-white text-center">Designed by <span>Eduardo Carranza</span></div>
        </footer>
  
      </div>
    );
  }//end render

}//end SideBar

export default SideBar;