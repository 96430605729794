import './projectDetailComponent.css';
import {Link} from 'react-router-dom';

const ProjectDetailView = (props) =>{
  return(
    <section id="projectDetail-section">
      <div className='container'>
        <nav className='nav align-items-center bg-light p-3'>
          <span className='heading me-auto p-2'>{`${props.project.name} Details`}</span>         
          <Link to="/" className='p-2'><span>Home</span></Link>
          <span className='p-2'>/</span>
          <span className='p-2'>Portfolio Details</span>
        </nav>
        <div className='row my-3'>
          {/* first column */}
          <div className='col-12 col-md-8'>
            <div className='content'>
              <div id='project-detail-carrousel' className='carousel slide' data-bs-ride="carousel" >
                <div className='carousel-indicators'>
                  {props.project.images.map((image , index)=>{
                    if(index == 0)
                      return <button key={`button-${index}`} type='button' data-bs-target="#project-detail-carrousel" data-bs-slide-to={index} className='active' ></button>;
                    else
                      return <button key={`button-${index}`} type='button' data-bs-target="#project-detail-carrousel" data-bs-slide-to={index}></button>;
                  })}
                </div>
                <div className='carousel-inner'>
                  {props.project.images.map((image , index)=>{

                    let img = <img className='d-block w-100' src={image}/>
                    if(index == 0)
                      return <div key={`item-${index}`} className='carousel-item active ratio ratio-16x9'>{img}</div>;
                    else
                      return <div key={`item-${index}`} className='carousel-item ratio ratio-16x9'>{img}</div>;

                  })}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#project-detail-carrousel" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#project-detail-carrousel" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
          {/* end first column */}

          {/* second column */}
          <div className='col-12 col-md-4'>
            <div className='content'>
              <div className='project-info p-3'>
                <h4>Project Information</h4>
                <div><span>Name:</span> {props.project.name}</div>
                <div><span>Category:</span> Web application</div>
                <div><span>Project Date:</span> {props.project.date}</div>
               <div><span>External Libraries: </span>
                  <ul>
                    {props.project.externalLibraries.map((library , index)=>{
                      return <li key={`item-${index}`}>{library}</li>;
                    })}
                  </ul>
                </div>
                <div><span>Project URL:</span> <a target="_blank" href={`${props.project.url}`}>{props.project.url}</a></div>
              </div>
              <div className='project-description p-3'>
                <h2>Project Description</h2>
                <p className='text-start'>{props.project.description}</p>
              </div>
            </div>
          </div>
          {/* end second column */}
        </div>
      </div>
    </section>
  );
}//end ProjectDetailView

export default ProjectDetailView;