import './sideBarToggleBtn.css';
import $ from 'jquery';

const ToggleButton = ()=>{
  const handleClick = (event)=>{
    $("#offcanvas").toggleClass("show");
    $("#offcanvas-toggle-btn span").toggleClass("fa-bars fa-times");
  }
  return(
     <button id="offcanvas-toggle-btn" type='button' className='btn d-lg-none text-white' onClick={handleClick}>
      <span className='fas fa-bars'></span>
     </button>
  );
}//end ToggleButton

export default ToggleButton;