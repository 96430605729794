import './resumeComponent.css'
import React from 'react';
import $, { extend } from 'jquery';

class Resume extends React.Component{
  
  constructor(props){
    super(props);
  }//end constructor

  componentDidMount(){
    if($(window).width() >= 992)
      $("#resume-section").width($(window).width() - $("#offcanvas").innerWidth()).css("margin-left" , `${$("#offcanvas").innerWidth()}px`)

    $(window).resize(()=>{
      if($(window).width() >= 992)
        $("#resume-section").width($(window).width() - $("#offcanvas").innerWidth()).css("margin-left" , `${$("#offcanvas").innerWidth()}px`)
      else
        $("#resume-section").width($(window).width()).css("margin-left" , '0');
    });
  }//end componentDidMount

  componentWillUnmount(){
    $(window).unbind();
  }//end componentWillUnmount

  render(){
    return (
      <section id="resume-section">
      <div className="container p-3">
        <header className="">
          <h1 className='main-heading text-start'>Resume</h1>
          <p id="resume-description" className='text-start'>
          I am a positive, responsible and disciplinary person committed to learn and deliver solutions that increase profitability for the business by leveraging strong exposure in Software Development while keeping a high level of quality.
          </p>
          <main className="m-3 p-3">
            <div className="row">
              {/* first column */}
              <div className="col-12 col-lg-6">
                <div className="content">
                  <div className="resume-subsection">
                    <h3 className="subsection-heading">Summary</h3>
                    <div className="subsection-body">
                      <div className="resume-item">
                        <h4 className="resume-item-title">Eduardo</h4>
                        <p id='summary-developer-description'>
                          Innovative and problem-solver software developer, with 3+ years of experience disigning and developing different types of
                          applications that range from desktop to the web.
                        </p>
                        <ul>
                          <li>Kendall Dr, Miami, Fl</li>
                          <li>(813) 447-6579</li>
                          <li>eduardocarranza0812@gmail.com</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="resume-subsection">
                    <h3 className="subsection-heading">Education</h3>
                    <div className="subsection-body">
                      <div className="resume-item">
                        <h4 className="resume-item-title">Front-end Web Developer Certificate</h4>
                        <span className="resume-item-year">2021-2022</span>
                        <p>Altcademy Bootscamp</p>
                        <p>
                          I have graduated from the Front-end Web Development online program provided by Altcademy Bootcamp, obtaining certificates in multiple
                          web standards, like HTML5 , CSS3 , JavaScript, and React. 
                        </p>
                      </div>
                      <div className="resume-item">
                        <h4 className="resume-item-title">Associate Degree in Computer Science</h4>
                        <span className="resume-item-year">2020-2021</span>
                        <p>Miami Dade College, Kendall Campus</p>
                        <p>
                          I have earned an Associate Degree in Computer Science from Miami Dade College, improving my existing insights and skills from prevoius
                          academic programs.  
                        </p>
                      </div>
                      <div className="resume-item">
                        <h4 className="resume-item-title">4 years of Computer Science</h4>
                        <span className="resume-item-year">2008-2012</span>
                        <p>University of Information Science, Havana, Cuba</p>
                        <p>
                          I have studied four years of Computer Science in my native country, Cuba, developing coding abilities and learning computer 
                          science fundamentals, such as different ways of development (Structured and Object-Oriented Paradigms), various
                          programming languages (C++, C#, JavaScript, Python), and so on.  
                        </p> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end first column */}

              {/* second column */}
              <div className="col-12 col-lg-6">
                <div className="content">
                  <div className="resume-subsection">
                    <h3 className="subsection-heading">Professional Experience</h3>
                    <div className="subsection-body">
                      <div className="resume-item">
                        <h4 className="resume-item-title">Full-stack Web Development</h4>
                        <span className="resume-item-year">2022 - Present</span>  
                        <p>Altcademy Bootscamp</p>
                        <p>
                        Currently I am doing the full - stack pathway provided by Altcademy Bootcamp in order to improve my programming skills; learning technologies like React, Ruby, and Ruby on Rails.
                        </p>
                      </div>
                      <div className="resume-item">
                        <h4 className="resume-item-title">Web and Desktop Developer</h4>
                        <span className="resume-item-year">2017 - 2018</span> 
                        <p>PAgroup Company , Miami, Florida </p> 
                        <p>
                          Worked as web and desktop developer for the IT team of the company, creating desktop applications as tools for supporting the company business. Applying technologies of the .net framework.
                        </p>
                      </div>
                      <div className="resume-item">
                        <h4 className="resume-item-title">Front-end and Back-end Web Developer</h4>
                        <span className="resume-item-year">2016</span>
                        <p>Freelance developer - Cuba </p>
                        <p>
                          Developed and designed the front-end and back-end of a web application responsible of managing the production of shoes of a private business. Including technologies, such as .Net platform (ASP.net, ADO.net, etc.) for the back end, and AJAX, JavaScript, jQuery, and others for the front-end.
                        </p>
                      </div>  
                      <div className="resume-item">
                        <h4 className="resume-item-title">Front-end UI Web Developer</h4>
                        <span className="resume-item-year">2015</span>
                        <p>Desoft , Pinar del Rio, Cuba</p>
                        <p>
                          Designed and developed responsive user interfaces for dynamic database driven websites. Including technologies, like HTML, Photoshop, client-side JavaScript, jQuery, and others.
                        </p>
                      </div>
                      <div className="resume-item">
                        <h4 className="resume-item-title">Front-end Components Developer</h4>  
                        <span className="resume-item-year">2013 - 2014</span>
                        <p>MICONS - Cuba </p>
                        <p>
                          Participated in the development of the front-end component of the main web site for the Ministry of Construction in Cuba. Making the web application looks and feel more interactive and consistent. Applying web standards, such as HTML5, CSS3, XML, AJAX, and Bootstrap framework. 
                        </p>
                      </div>
                      <div className="resume-item">
                        <h4 className="resume-item-title">Front-end UI Web Developer</h4>
                        <span className="resume-item-year">2010 - 2012</span>
                        <p>Virtual Learning Labs Developer</p>
                        <p>
                          Participated in the development of Virtual Learning Labs, developing functionalities for the Library Module. Applying technologies, like jQuery, CSS, JavaScript, XML, Microsoft SQL Server, and Entity Framework (.Net). 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end second column */}
            </div>
          </main>
        </header>
      </div>
    </section>
    );
  }//end render
}


export default Resume;