import React from 'react';
import './aboutComponent.css';
import $ from 'jquery';
import aboutPicture from './personal-pictures/Navy blue Original.jpg';

class About extends React.Component{
  
  constructor(props){
    super(props);
  }//end constructor

  componentDidMount(){
    if($(window).width() >= 992)
      $("#about-section").width($(window).width() - $("#offcanvas").innerWidth()).css("margin-left" , `${$("#offcanvas").innerWidth()}px`);

    $(window).resize(()=>{
      if($(window).width() >= 992)
        $("#about-section").width($(window).width() - $("#offcanvas").innerWidth()).css("margin-left" , `${$("#offcanvas").innerWidth()}px`);
      else
      $("#about-section").width($(window).width()).css("margin-left" , '0');
    });
  }//end componentDidMount

  componentWillUnmount(){
    $(window).unbind();
  }//end componentWillUnmount

  render(){
    return (
      <section id='about-section'>
        <div className='container p-3'>
          <h1 className='main-heading'>Hi, I'm Eduardo</h1>
          <p>
            I'm from Cuba, Pinar del Rio city, now living in Miami, USA. From child I liked the world of mathematics and computers.
            I liked to understand how computers work and the multiple components that make up a computer system, such as RAMs, Microprocessors,
            Microchips, and so on. Therefore, I decided later to study Computer Science and become a software developer. Beside Information
            Technology field, in my spare time I love to watch soccer, specifically the Spanish League, following one of the best team on the world,
            Barcelona.
          </p>

          <div className='row'>
            {/* first column */}
            <div className='col-12 col-lg-4'>
              <div className='content p-2'>
                <img id='about-image' src={aboutPicture} className='img-fluid img-thumbnail ration ratio-1x1' alt='About Picture'/>
              </div>
            </div>
            {/* end first column */}

            {/* second column */}
            <div className='col-12 col-lg-8'>
              <div className='about-subsection p-2'>
                <h3 className='subsection-heading'>UI/UX Designer & Web Developer</h3>
                <p>
                  As web developer, I like to design and developer different layouts and controls to power
                  the Front-End of a web application, making the application to look and feel more consistent.
                </p>
                {/* subrow */}
                <div className='row'>
                  {/* first column */}
                  <div className='col-12 col-lg-6'>
                    <div className='content'>
                      <ul>
                        <li><span className='fas fa-chevron-right'></span><span> Birthday:</span> December 8, 1990</li>
                        <li><span className='fas fa-chevron-right'></span> <span>Website:</span> www.example.com</li>
                        <li><span className='fas fa-chevron-right'></span> <span>Phone:</span> (813) 447-6579</li>
                      </ul>
                    </div>
                  </div>
                  {/* end first column */}

                  {/* second column */}
                  <div className='col-12 col-lg-6'>
                    <div className='content'>
                    <ul>
                        <li><span className='fas fa-chevron-right'></span> <span>City:</span> Miami, USA</li>
                        <li><span className='fas fa-chevron-right'></span> <span>Degree:</span> Associate in Computer Science</li>
                        <li><span className='fas fa-chevron-right'></span> <span>Email:</span> eduardocarranza0812@gmail</li>
                      </ul>
                    </div>
                  </div>
                  {/* end second column */}
                </div>
                {/* end subrow */}

              </div>
            </div>
            {/* end second column */}
          </div>
        </div>
      </section>
    );
  }//end render

}//end About

export default About;
