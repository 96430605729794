import SideBar from './sideBar';
import ToggleButton from './sideBarToggleBtn';
import Home from './homeComponent';
import Resume from './resumeComponent';
import About from './aboutComponent';
import {Route , Link , Switch} from 'react-router-dom';
import Portfolio from './portfolioComponent';
import ProjectInfo from './projectInfo';
import React from 'react';
import ProjectDeatilView from './projectDetailComponent';
import $ from 'jquery';
import Contact from './contactComponent';

// importing images
import exchange_app_1 from './project-Images/exchange-app-1.JPG';
import exchange_app_2 from './project-Images/exchange-app-2.JPG';
import exchange_app_3 from './project-Images/exchange-app-3.JPG';

import movie_finder_app_1 from './project-Images/movie-finder-app-1.JPG';
import movie_finder_app_2 from './project-Images/movie-finder-app-2.JPG';

import pm_aviation_services_1 from './project-Images/pm-aviation-services-1.JPG';
import pm_aviation_services_2 from './project-Images/pm-aviation-services-2.JPG';

import shopping_cart_app_1 from './project-Images/shopping-cart-app-1.JPG';
import shopping_cart_app_2 from './project-Images/shopping-cart-app-2.JPG';

import to_do_list_app_1 from './project-Images/to-do-list-1.JPG';
import to_do_list_app_2 from './project-Images/to-do-list-2.JPG';

// end importing images

class App extends React.Component{
  
  constructor(props){
    super(props);

    this.state = {
      projects: {

        exchangeApp: new ProjectInfo("Exchange App", "February 23, 2022" , "https://ec-currency-exchange.netlify.app",`
        The project consists of two modules, a currency exchange rates listing , and a currency converter.
        The currency rates listing shows a base currency against a list of currencies, where the user
        can change the base currency and as result the list of rates will be updated.
        On the other way, the currency converter module, as its name implies is used to convert a pair of currency,
        allowing the user to swap the direction of the currency pair and edit the amount to convert.
        Currency exchange rate data is provided via the Frankfurther open-source API, which tracks exchange rates for 33
        different currencies, which are:
        AUD, BGN, BRL, CAD, CHF, CNY, CZK, DKK, EUR, GBP, HKD, HRK, HUF, IDR, ILS, INR, ISK, JPY, KRW, MXN, MYR, NOK, NZD, PHP, PLN, RON, RUB, SEK, SGD, THB, TRY, USD, ZAR.
        In addition, the project provides a chart to show historical prices of a currency pair.
        ` , ["Bootstrap" , "jQuery" , "React" , "Chart"], exchange_app_1 , exchange_app_2 , exchange_app_3),

        shoppingCartApp: new ProjectInfo("Shopping Cart", "September 19, 2021", "https://competent-meitner-118759.netlify.app", `
        The project shows a basic and functioning shopping cart that allows the user to calculate and display the total price,
        calculate and display the sub total price of each item, and add and remove a new item.
        `,["Bootstrap" , "jQuery"] , shopping_cart_app_1 , shopping_cart_app_2),

        toDoListApp: new ProjectInfo("To Do List" , "September 26, 2021" , "https://xenodochial-montalcini-eec466.netlify.app" , `
        The project implements a simple To Do List application which is driven by a database service to provide data persistance.
        The project allows the user to create, remove, and change the state of a task from active to complete, communicating with the 
        database service via AJAX requests.
        ` , ["Bootstrap" , "jQuery" , "AJAX"] , to_do_list_app_1 , to_do_list_app_2),

        movieFinderApp: new ProjectInfo("Movie Finder", "December 10, 2021", "https://objective-archimedes-fe25c6.netlify.app" ,`
        The project represents a Movie Finder application which allows the user to search for movies by a search term.
        Movie data is provided via the open movie database service. The application communicates with the database service
        through the use of the Fetch API.
        `, ["Bootstrap" , "jQuery" ,"React" , "Fetch"] , movie_finder_app_1 , movie_finder_app_2),

        pmAviationServiceApp: new ProjectInfo("PM Aviation Service Inc." , "January 20, 2022", "https://amazing-mccarthy-131cbb.netlify.app", `
        The project implements the front-end component of a web applicaton developed for the company P&M Aviation Services Inc.
        This company is located close to the Miami's International Airport and focus on the commercialization of spare parts, 
        components, and services related to aircraft, engines, propellers and systems associated with aeronautics in general.
        `, ["Bootstrap" , "jQuery"] , pm_aviation_services_1 , pm_aviation_services_2)
      },

      currentProject: ""
    }

    this.handleClick = this.handleClick.bind(this);
  }//end constructor

  handleClick(event){
    const column = $(event.target);
    const image = column.parents(".content.ratio").children("img");
    this.setState({
      currentProject: image.attr("id")
    });

  }//end handleClick

  render(){
    return (
      <div className="App">
        <Route exact path='/'>
          <SideBar />
          <ToggleButton />
          <Home />
          <About />
          <Resume />
          <Portfolio projects={this.state.projects} viewDetailClick={this.handleClick}/>
          <Contact />
        </Route>
        <Route exact path="/portfolio-details">
          <ProjectDeatilView project={this.state.projects[this.state.currentProject]} />
        </Route>
      </div>
    );
  }//end render
}

export default App;
